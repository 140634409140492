import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Spiro = () => {
	const { spiro: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `A/Prof Spiro Raftopoulos`]}
				description="About A/Prof Spiro Raftopoulos"
			/>
			<ProfilePage
				title="A/Prof Spiro Raftopoulos - Interventional Endoscopist & Gastroenterologist"
				name="A/Prof Spiro Raftopoulos"
				img={pic.childImageSharp.fixed}
				quote="Always listen to your patients. They known themselves better than anyone else..."
				specialty="All aspects of interventional endoscopy including endoscopic retrograde cholangiopancreatography (ERCP)"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Fellowship: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Fellowship: St Michael's Hospital, Toronto Canada",
				]}
				description={[
					"Spiro prides himself in the delivery of expert management in all aspects of general gastroenterology, therapeutic endoscopy and high quality colonoscopic services.",
					"His specific interests lie in the provision of minimally invasive endoscopic alternatives to surgery for a variety of conditions including Barrett’s Oesophagus with access to the latest endoscopic techniques including endoscopic resection (EMR and ESD) and radiofrequency ablation, endoscopic management of advanced and complex Colonic Polyps, endoscopic management of Achalasia including POEM (per oral endoscopic myotomy) procedure, endoscopic management of Pharyngeal Pouches (Zenker’s Diverticulum) and cricopharyngeal spasm (including Z-POEM, C-POEM and Bougie Dilation), endoscopic management of oesophageal diverticulae (D-POEM) and all aspects of Eosinophilic Oesophagitis management including dilation.",
					"Spiro works closely with the Genetics Services at King Edwards Memorial Hospital and provides high quality surveillance gastroscopy and colonoscopy for herediatry cancer syndromes such as FAP, Lynch and Serrated Polyposis Syndrome. ",
				]}
				honours={[
					"A&A Saw Medical Research Fellowship 2010",
					"WA Gastroenterology Trainee Research Prize 2008, 2009",
					"Premier Service Award – SCGH 2008",
				]}
				background="Spiro completed his gastroenterology training in Perth. He subsequently undertook fellowship training at the prestigious St Michael’s Hospital in Toronto Canada in interventional endoscopy before returning to Perth and being appointed as a Consultant Gastroenterologist at Sir Charles Gairdner Hospital."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Private/Public appointment - Peel Health Campus, Mandurah",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Clinical lecturer of medicine - The University of Western Australia",
				]}
				memberships={[
					"Australian and New Zealand Endoscopy Leaders Forum (ANZELF) - Present Chair",
					"Cancer Council of Australia",
					"Royal Australasian College of Pathologist",
					"Gastroenterological Society of Australia (GESA)",
					"UWA CTEC Platinum Convenor ",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"The Royal Australasian College of Physicians (RACP)",
					"Australian Medical Association (AMA)",
				]}
				personal={[
					"I have a wide range of interests including kitesurfing, fishing and diving. I most enjoy time with my family and friends. As a child, I always had an interest in fixing things which is what drove me towards a career in medicine. Treating and healing patients with a variety of conditions has been the ultimate professional reward.",
				]}
				publications={[
					{
						publicationTitle: "Hot avulsion versus argon plasma coagulation for the management of the non-ensnarable polyp: A multicenter, randomized controlled trial",
						authors: "Chloe Attree, Ravinder Ogra, Ian F Yusoff, Alan C Moss, Angela Jacques, Gregor Brown, Sina Alexander, Marios Efthymiou, Spiro Raftopoulos",
					},
					{
						publicationTitle: "When less is more: lower esophageal sphincter-preserving peroral endoscopic myotomy is effective for non-achalasia esophageal motility disorders.",
						authors: "Perananthan V, Gupta S, Whitfield A, Craciun A, Cronin O, O'Sullivan T, Byth K, Sidhu M, Hourigan LF, Raftopoulos S, Burgess NG, Bourke MJ.",
					},
					{
						publicationTitle: "The Surface Morphology of Large Nonpedunculated Colonic Polyps Predicts Synchronous Large Lesions.",
						authors: "O'Sullivan T, Tate D, Sidhu M, Gupta S, Elhindi J, Byth K, Cronin O, Whitfield A, Craciun A, Singh R, Brown G, Raftopoulos S, Hourigan L, Moss A, Klein A, Heitman S, Williams S, Lee E, Burgess NG, Bourke MJ.",
					},
					{
						publicationTitle: "Effectiveness and safety of cold snare polypectomy and cold endoscopic mucosal resection for nonpedunculated colorectal polyps of 10-19 mm: a multicenter observational cohort study",
						authors: "Dileep Mangira, Spiro Raftopoulos, Sara Vogrin, Imogen Hartley, Alexandra Mack, Kathryn Gazelakis, Kumanan Nalankilli, Andrew Trinh, Andrew J Metz, Mark Appleyard, Florian Grimpen, Timothy Elliott, Gregor Brown, Alan Moss",
					},
					{
						publicationTitle: "Anterior pouch flexible endoscopic septal division: an alternative therapy for patients with a neopharyngeal pseudodiverticulum",
						authors: "Victoria S. L. Kok, Spiro Raftopoulos",
					},
					{
						publicationTitle: "To investigate outcomes in endoscopic management of early oesophageal adenocarcinoma in Barrett oesophagus: experience at three Australian tertiary centres",
						authors: "Puraskar Pateria, Andre Chong, Niroshan Muwanwella, Chiang Siah, Priyanthi Kumarasinghe, Spiro Raftopoulos",
					},
					{
						publicationTitle: "Oncological outcomes after piecemeal endoscopic mucosal resection of large non-pedunculated colorectal polyps with covert submucosal invasive cancer",
						authors: "Dave J Gibson, Mayenaaz Sidhu, Simon Zanati, David J Tate, Dileep Mangira, Alan Moss, Rajvinder Singh, Luke F Hourigan, Spiro Raftopoulos, Alan Pham, Phil Kostos,M Priyanthi Kumarasinghe, Andrew Ruszkiewicz, Duncan McLeod, Gregor J E Brown, Michael J Bourke",
					},
					{
						publicationTitle: "Clinical and pathological predictors of failure of endoscopic therapy for Barrett’s related high-grade dysplasia and early esophageal adenocarcinoma",
						authors: "Yuto Shimamura, Yugo Iwaya, Ryosuke Kobayashi, Enrique Rodriguez de Santiago, Niroshan Muwanwella, Spiro Raftopoulos, Jeffrey D. Mosko, Gary R. May, Gabor Kandel, Paul Kortan, Norman Marcon & Christopher W. Teshima",
					},
					{
						publicationTitle: "Outcomes of thermal ablation of the mucosal defect margin after endoscopic mucosal resection: A prospective, international, multi-centre trial of 1000 large non-pedunculated colorectal polyps",
						authors: "Mayenaaz Sidhu, Neal Shahidi, Sunil Gupta, Lobke Desomer, Sergei Vosko, W. Arnout van Hattem, Luke F. Hourigan, Eric Y.T. Lee, Alan Moss, Spiro Raftopoulos, Steve J. Heitman, Stephen J. Williams, Simon Zanati, David J. Tate, Nicholas Burgess, Michael J. Bourke",
					},
					{
						publicationTitle: "A prospective multicentre study of per-oral endoscopic myotomy (POEM) for achalasia in Australia",
						authors: "Sunil Gupta, Mayenaaz Sidhu, Xuan Banh, Joseph Bradbear, Karen Byth, Luke F Hourigan, Spiro Raftopoulos, Michael J Bourke",
					},
					{
						publicationTitle: "The modern management of Barrett’s oesophagus and related neoplasia: role of pathology",
						authors: "M Priyanthi Kumarasinghe, Michael Armstrong, Jonathan Foo, Spiro C Raftopoulos",
					},
					{
						publicationTitle: "International validation of a risk prediction algorithm for patients with malignant colorectal polyps",
						authors: "C. Richards, K. Levic, J. Fischer, T. Eglinton, G. Ramsay, P. Kumarasinghe, S. Raftopoulos, I. Brown",
					},
					{
						publicationTitle: "Revising the definition of vertical margin involvement following endoscopic polypectomy may reduce unnecessary surgery in patients with malignant colorectal polyps",
						authors: "Richards C, Kumarasinghe P, Hessamodini H, Waldron A, Fernando D, Hodder R, Jacques A, Raftopoulos S",
					},
					{
						publicationTitle: "Cold snare piecemeal EMR of large sessile colonic polyps ≥20 mm (with video)",
						authors: "Mangira D, Cameron K, Simons K, Zanati S, LaNauze R, Raftopoulos S, Brown G, Moss A",
					},
					{
						publicationTitle: "Pathological assessment of endoscopic resections of the gastrointestinal tract: a comprehensive clinicopathologic review ",
						authors: "Kumarasinghe MP, Bourke MJ, Brown I, Draganov PV, McLeod D, Streutker C, Raftopoulos S, Ushiku T, Lauwers GY",
					},
					{
						publicationTitle: "Impact of en bloc resection on long-term outcomes after endoscopic mucosal resection: a matched cohort study",
						authors: "Tate DJ, Sidhu M, Bar-Yishay I, Desomer L, Brown G, Hourigan LF, Lee EYT, Moss A, Raftopoulos S, Singh R, Williams SJ, Zanati S, Burgess N, Bourke MJ",
					},
					{
						publicationTitle: "Definitive therapy of colonic angioectasia by submucosal coagulation",
						authors: "Sriram N, Bar-Yishay I, Kumarasinghe P, Yusoff I, Segarajasingam D, Bourke MJ, Raftopoulos S",
					},
					{
						publicationTitle: "Patterns of p53 immunoreactivity in non-neoplastic and neoplastic Barrett's mucosa of the oesophagus: in-depth evaluation in endoscopic mucosal resections",
						authors: "Toon C, Allanson B, Leslie C, Acott N, Mirzai B, Raftopoulos S, Kumarasinghe MP",
					},
					{
						publicationTitle: "The size, morphology, site, and access score predicts critical outcomes of endoscopic mucosal resection in the colon",
						authors: "Sidhu M, Tate DJ, Desomer L, Brown G, Hourigan LF, Lee EYT, Moss A, Raftopoulos S, Singh R, Williams SJ, Zanati S, Burgess N, Bourke MJ",
					},
					{
						publicationTitle: "Hot avulsion may be effective as salvage treatment for focal Barrett's esophagus remaining after endoscopic therapy for dysplasia or early cancer: a preliminary study",
						authors: "Aranda-Hernández J, Shimamura Y, Grin A, Iwaya Y, Cirocco M, Kandel G, May G, Kortan P, Raftopoulos S, Marcon N",
					},
					{
						publicationTitle: "Risk Stratification for Covert Invasive Cancer Among Patients Referred for Colonic Endoscopic Mucosal Resection: A Large Multicenter Cohort",
						authors: "Burgess NG, Hourigan LF, Zanati SA, Brown GJ, Singh R, Williams SJ, Raftopoulos SC, Ormonde D, Moss A, Byth K, Mahajan H, McLeod D, Bourke MJ",
					},
					{
						publicationTitle: "Early Barrett esophagus-related neoplasia in segments 1 cm or longer is always associated with intestinal metaplasia",
						authors: "Allanson BM, Bonavita J, Mirzai B, Khor TS, Raftopoulos SC, de Boer WB, Brown IS, Kumarasinghe MP",
					},
					{
						publicationTitle: "Standardization of endoscopic resection for colorectal tumors larger than 10 mm in diameter",
						authors: "Oka S, Uraoka T, Tamai N, Ikematsu H, Chino A, Okamoto K, Takeuchi Y, Imai K, Ohata K, Shiga H, Raftopoulos S, Lee BI, Matsuda T",
					},
					{
						publicationTitle: "Adenoma recurrence after piecemeal colonic EMR is predictable: the Sydney EMR recurrencetool",
						authors: "Tate DJ, Desomer L, Klein A, Brown G, Hourigan LF, Lee EY, Moss A, Ormonde D, Raftopoulos S, Singh R, Williams SJ, Zanati S, Byth K, Bourke MJ",
					},
					{
						publicationTitle: "Prediction of Clinically Significant Bleeding Following Wide-Field Endoscopic Resection of Large Sessile and Laterally Spreading Colorectal Lesions: A Clinical Risk Score",
						authors: "Bahin FF, Rasouli KN, Byth K, Hourigan LF, Singh R, Brown GJ, Zanati SA, Moss A, Raftopoulos S, Williams SJ, Bourke MJ",
					},
					{
						publicationTitle: "Endoscopic mucosal resection for large serrated lesions in comparison with adenomas: a prospective multicentre study of 2000 lesions",
						authors: "Pellise M, Burgess NG, Tutticci N, Hourigan LF, Zanati SA, Brown GJ, Singh R, Williams SJ, Raftopoulos SC, Ormonde D, Moss A, Byth K, P'Ng H, Mahajan H, McLeod D, Bourke MJ",
					},
					{
						publicationTitle: "Australian New Zealand Liver Transplant Study Group. Additive impact of pre-liver transplant metabolic factors on survival post-liver transplant",
						authors: "Adams LA, Arauz O, Angus PW, Sinclair M, MacDonald GA, Chelvaratnam U, Wigg AJ, Yeap S, Shackel N, Lin L, Raftopoulos S, McCaughan GW, Jeffrey GP",
					},
					{
						publicationTitle: "Clinical and endoscopic predictors of cytological dysplasia or cancer in a prospective multicentre study of large sessile serrated adenomas/polyps",
						authors: "Burgess NG, Pellise M, Nanda KS, Hourigan LF, Zanati SA, Brown GJ, Singh R, Williams SJ, Raftopoulos SC, Ormonde D, Moss A, Byth K, P'Ng H, McLeod D, Bourke MJ",
					},
					{
						publicationTitle: "Australian clinical practice guidelines for the diagnosis and management of Barrett's esophagus and early esophageal adenocarcinoma",
						authors: "Whiteman DC, Appleyard M, Bahin FF, Bobryshev YV, Bourke MJ, Brown I, Chung A, Clouston A, Dickins E, Emery J, Eslick GD, Gordon LG, Grimpen F, Hebbard G, Holliday L, Hourigan LF, Kendall BJ, Lee EY, Levert-Mignon A, Lord RV, Lord SJ, Maule D, Moss A, Norton I, Olver I, Pavey D, Raftopoulos S, Rajendra S, Schoeman M, Singh R, Sitas F, Smithers BM, Taylor AC, Thomas ML, Thomson I, To H, von Dincklage J, Vuletich C, Watson DI, Yusoff IF",
					},
					{
						publicationTitle: "Prophylactic endoscopic coagulation to prevent bleeding after wide-field endoscopic mucosal resection of large sessile colon polyps",
						authors: "Bahin FF, Naidoo M, Williams SJ, Hourigan LF, Ormonde DG, Raftopoulos SC, Holt BA, Sonson R, Bourke MJ",
					},
					{
						publicationTitle: "Hot avulsion: a modification of an existing technique for management of nonlifting areas of a polyp (with video)",
						authors: "Veerappan SG, Ormonde D, Yusoff IF, Raftopoulos SC",
					},
					{
						publicationTitle: "Intramural gastric abscess from dropped gallstones presenting as a subepithelial lesion (with video)",
						authors: "Chen HC, Tee CT, Segarajasingam DS, Raftopoulos SC, Yusoff IF",
					},
					{
						publicationTitle: "Image analysis of liver collagen using sirius red is more accurate and correlates better with serum fibrosis markers than trichrome",
						authors: "Huang Y, de Boer WB, Adams LA, MacQuillan G, Rossi E, Rigby P, Raftopoulos SC, Bulsara M, Jeffrey GP",
					},
					{
						publicationTitle: "Education and Imaging. Gastrointestinal: A sheep in wolf's clothing",
						authors: "Raftopoulos SC, Efthymiou M, Marcon N, Streutker C",
					},
					{
						publicationTitle: "Wire assisted transpancreatic septotomy, needle knife precut or both for difficult biliary access",
						authors: "Chan CH, Brennan FN, Zimmerman MJ, Ormonde DG, Raftopoulos SC, Yusoff IF",
					},
					{
						publicationTitle: "Loop tip wire guided cannulation is a safe and effective technique even when performed by trainee endoscopists. (abstract)",
						authors: "Chan CH, Brennan FC, Raftopoulos SC, Yusoff F",
					},
					{
						publicationTitle: "Outcomes of endoscopic mucosal resection of Barrett’s mucosa with high grade dysplasia and intramucosal oesophageal cancer. (abstract)",
						authors: "Raftopoulos SC, Ormonde D, Brennan FN, Yusoff IF",
					},
					{
						publicationTitle: "Gastric intraepithelial neoplasia in a Western population",
						authors: "Raftopoulos SC, Kumarasinghe P, de Boer B, Iacobelli J, Kontorinis N, Fermoyle S, Olynyk J, Forrest C, Ee HC, Yusoff IF",
					},
					{
						publicationTitle: "Education and imaging. Gastrointestinal: Atypical hyperplastic gastric polyposis: a case report and brief review of the literature",
						authors: "Raftopoulos SC, Efthymiou M, Streutker CJ, May GR",
					},
					{
						publicationTitle: "Education and imaging. Gastrointestinal: beware of the painful rectum",
						authors: "Raftopoulos SC, Efthymiou M, Kortan P",
					},
					{
						publicationTitle: "Dysplastic Barrett's esophagus in cirrhosis: a treatment dilemma",
						authors: "Raftopoulos SC, Efthymiou M, May G, Marcon N",
					},
					{
						publicationTitle: "Flexible endoscopic septoplasty for bilobed Zenker's diverticulum",
						authors: "Efthymiou M, Raftopoulos S, Marcon N",
					},
					{
						publicationTitle: "Comparison of noninvasive models of fibrosis in chronic hepatitis B",
						authors: "Raftopoulos SC, George J, Bourliere M, Rossi E, de Boer WB, Jeffrey GP, Bulsara M, Speers DJ, MacQuillan G, Ching HL, Kontorinis N, Cheng W, Flexman J, Fermoyle S, Rigby P, Walsh L, McLeod D, Adams LA",
					},
					{
						publicationTitle: "Air embolism complicated by left hemiparesis after direct cholangioscopy with an intraductal balloon anchoring system",
						authors: "Efthymiou M, Raftopoulos S, Antonio Chirinos J, May GR",
					},
					{
						publicationTitle: "An unusual cause of small-intestine mucosal pigmentation",
						authors: "Raftopoulos SC, Fermoyle S",
					},
					{
						publicationTitle: "A cohort study of missed and new cancers after esophagogastroduodenoscopy",
						authors: "Raftopoulos SC, Segarajasingam DS, Burke V, Ee HC, Yusoff IF",
					},
					{
						publicationTitle: "Levosimendan following coronary artery bypass grafting in a patient with end-stage renal failure: a case report",
						authors: "Raftopoulos SC",
					},
					{
						publicationTitle: "Epstein-Barr virus-associated cholangiopathy: a new disease entity?",
						authors: "Raftopoulos SC, Garas G, Price R, de Boer WB, Jeffrey GP, Yusoff IF",
					},
				]}
			/>
		</>
	);
};

export default Spiro;
